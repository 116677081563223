import React, { useEffect, useContext } from "react"
import { Helmet } from "react-helmet"
import { Link, graphql, StaticQuery } from "gatsby"
import { Image, UILink, UIChip } from "./"
import classNames from "classnames"
import { Context } from "../store/ContextProvider"
import gsap, { Elastic } from "gsap/all"
import { Button } from "@mui/material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import "../templates/styles/projects-page.scss"

const ProjectRoll = ({ data, helmet }) => {
  const { edges: projects } = data.allMarkdownRemark

  const { state: { isMenuOpen } = { isMenuOpen: false } } =
    useContext(Context) || {}

  const animateProjects = () => {
    gsap
      .timeline({
        defaults: {
          ease: Elastic.easeOut.config(0.4, 0.5),
        },
      })
      .set(".project-list-item", { stagger: 0, autoAlpha: 0, y: 100 })
      .fromTo(
        ".project-list-item",
        { stagger: 0, autoAlpha: 0, y: 100 },
        { stagger: 0.1, autoAlpha: 1, y: 0 }
      )
      .fromTo(
        ".featured-thumbnail",
        { y: 100, autoAlpha: 0 },
        { y: 0, autoAlpha: 1 },
        "< 0.1"
      )
  }

  useEffect(() => {
    if (!isMenuOpen) animateProjects()
  }, [isMenuOpen])

  return (
    <div className="project">
      {helmet || ""}
      <div className="project__wrapper">
        {projects &&
          projects.map(({ node: project }) => (
            <article
              key={project.id}
              className={classNames(`project-list-item`, {
                "is-featured": project.frontmatter.featuredpost,
              })}
            >
              <header>
                {project.frontmatter.featuredimage ? (
                  <div className="featured-thumbnail">
                    <UIChip
                      className="is-assignment--chip chip chip-tag"
                      text={
                        project.frontmatter.isAssignment ? "Assignment" : "Demo"
                      }
                    />
                    <Image
                      image={project.frontmatter.featuredimage}
                      alt={`${project.frontmatter.title}`}
                    />
                  </div>
                ) : null}
              </header>
              <div className="project-content">
                <div className="project-name">
                  <p className="project-tech">
                    {project.frontmatter.technologies?.slice(0, 3)?.join(" • ")}
                  </p>
                  <h3 className="post-meta">
                    <Link to={project.fields.slug}>
                      {project.frontmatter.title}
                    </Link>
                  </h3>
                </div>
              </div>
              <div className="project-content--bottom">
                <UILink to={project.fields.slug}>
                  <Button
                    color="secondary"
                    className="button"
                    size={"small"}
                    disableElevation
                    variant="contained"
                  >
                    View More <ChevronRightIcon />
                  </Button>
                </UILink>
              </div>
            </article>
          ))}
      </div>
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "project-page" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 300)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMM DD, YYYY")
                technologies
                featuredpost
                isAssignment
                featuredimage {
                  childImageSharp {
                    gatsbyImageData(
                      width: 500
                      quality: 100
                      placeholder: BLURRED
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <ProjectRoll
        data={data}
        count={count}
        helmet={
          <Helmet titleTemplate={"Tamir Assayag | %s"}>
            <title>Projects</title>
          </Helmet>
        }
      />
    )}
  />
)
